// Oimport Vue from 'vue'
import App from './App.vue'
import { createApp } from 'vue'
import cognitoAuth from './cognito/index.js'
import config from './aws-exports.js'
import { router } from './router/router.js'
import { vuetify } from './plugins/vuetify'

// Vue.config.productionTip = false

const app = createApp(App)
app.provide('apiBase', import.meta.env.VITE_SYNDAE_API_BASE)
app.use(router)
app.use(cognitoAuth, config)
app.use(vuetify)
app.mount('#app')

