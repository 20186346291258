import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const myCustomLightTheme = {
  // dark: false,
  colors: {
    primary: '#C2BC0A',
    secondary: '#0978AC',
    accent: '#FBA53B',
    warning: '#C2470A',
    error: '#C2470A',
  }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme
      }  
    }
  })

export { vuetify } 