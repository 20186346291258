<template>
  <v-container fluid>
    <v-row
      align="start"
      justify="center">
      <v-col class="title text--primary" cols=10>
        <v-card outlined>
          <v-row>
            <v-col cols="2">
              <v-img src="./syndae.png" max-width="100"></v-img>
            </v-col>
            <v-col cols="10">
              <v-card-title>Syndae</v-card-title>
              <v-card-subtitle>Make Sound.</v-card-subtitle>
              <v-card-text>
                Distributed audio synthesis.</v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HomeView',
  data: function() {
    return {
    }
  }
}
</script>
