<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      width="200"
    >
      <v-list> 
        <v-list-item to="/">
            <template v-slot:prepend>
              <v-icon>mdi-home-circle-outline</v-icon>
            </template>
            <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item to="daemons">
            <template v-slot:prepend>
              <v-icon>mdi-sine-wave</v-icon>
            </template>
            <v-list-item-title>Daemons</v-list-item-title>
        </v-list-item>
        <v-list-item to="fons">
            <template v-slot:prepend>
              <v-icon>mdi-piano</v-icon>
            </template>
            <v-list-item-title>Fons</v-list-item-title>
        </v-list-item>
        <v-list-item to="patches">
            <template v-slot:prepend>
              <v-icon>mdi-text-box-outline</v-icon>
            </template>
            <v-list-item-title>Patches</v-list-item-title>
        </v-list-item>
        <v-list-item to="edit">
            <template v-slot:prepend>
              <v-icon>mdi-shimmer</v-icon>
            </template>
            <v-list-item-title>Editor</v-list-item-title>
        </v-list-item>
        <v-list-item to="about">
            <template v-slot:prepend>
              <v-icon>mdi-help</v-icon>
            </template>
            <v-list-item-title>About</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdmin" to="admin">
            <template v-slot:prepend>
              <v-icon>mdi-wrench</v-icon>
            </template>
            <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>
      </v-list>
      <!-- <v-divider v-if="$root.editors.length > 0"></v-divider>
      <v-list>
        <v-subheader v-if="$root.editors.length > 0">Editors</v-subheader>

          <v-list-item
            v-for="(editorData, i) in $root.editors"
            :key="i"
            link
            :to="'/edit/' + editorData.id"
            dense
          >
            <v-list-item-title v-text="editorData.name"></v-list-item-title>
          </v-list-item>

      </v-list> -->
    </v-navigation-drawer>

    <v-app-bar
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-list-item>
        <template v-slot:prepend>
          <v-img
          alt="Syndae logo"
          class="shrink mr-2"
          contain
          src="./syndae.png"
          transition="scale-transition"
          width="35"
        />
        </template>

        <v-toolbar-title>syndae</v-toolbar-title>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-btn v-if="!loggedIn" outlined to="/login">
        Login
      </v-btn>

      <v-menu
        bottom
        offset-y
        dark
        v-if="loggedIn" 
      >
        <template v-slot:activator="{ props }">
          <v-btn icon small color="amber-darken-2" variant="outlined" v-bind="props">
            {{ initials }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/logout">
            <template v-slot:prepend>
              <v-icon>mdi-logout</v-icon>
            </template>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
          <v-list-item to="/settings">
            <template v-slot:prepend>
              <v-icon>mdi-cog</v-icon>
            </template>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

  <v-main>
    <v-container
      fluid
    >
    <router-view @loginStatus="loginStatus"></router-view>
    </v-container>
  </v-main>
    
  <v-footer
      app
      dark
    >
    <span>&copy; 2023 Gustav Lidén</span>
  </v-footer>

</v-app>
</template>

<script>
import cognitoAuth from '@/cognito'
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: null,
    loggedIn: false,
    initials: "",
    userName: "",
    isAdmin: false,
    appBarActionText: "", 
    appBarAction: "", 
    user_dialog: false
  }),

  methods: {
    loginStatus(loggedIn) {
      this.loggedIn = loggedIn
      this.updateAuthData()
    },

    updateAuthData() {
      cognitoAuth.isAuthenticated((res, authenticated) => {
        // if (authenticated) {
          this.loggedIn = authenticated;
          if (authenticated) {
            this.userName = res.idToken.payload.given_name;
            this.initials = res.idToken.payload.given_name.charAt(0) + '' + res.idToken.payload.family_name.charAt(0)
            this.isAdmin = res.idToken.payload['cognito:groups'].includes('admins');
          }
      })
    }
  },

  mounted() {
    this.updateAuthData();
  }
};
</script>
