// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import cognitoAuth from '@/cognito'
import { createRouter, createWebHistory } from 'vue-router'

// Vue.use(VueRouter)


function requireAuth (to, from, next) {
  // next()
  cognitoAuth.isAuthenticated((err, loggedIn) => {
    console.log('isAuthenticated returned: ', loggedIn)
    if (err) return next()
    if (!loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/daemons',
    name: 'Daemons',
    component: () => import('../views/Daemons.vue'),
    // component: Daemons,
    beforeEnter: requireAuth,
  },
  {
    path: '/fons',
    name: 'Fons',
    component: () => import('../views/Fons.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/patches',
    name: 'Patches',
    component: () => import('../views/Patches.vue'),
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/editor',
  //   name: 'Editor',
  //   component: () => import('../views/editor/Editor.vue'),
  //   beforeEnter: requireAuth,
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/confirmregistration',
    name: 'Confirm Registration',
    component: () => import('../views/ConfirmRegistration.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('../views/edit/Edit.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    beforeEnter: requireAuth,
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

export { router }
